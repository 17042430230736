<template>
  <div class="product-slider" v-if="products">
    <div class="search-result" v-if="products.length > 0">
      <div class="d-flex mb-4">
        <span class="text-h6">
          {{ itemsPerPage }} risultati di {{ page.totItems }} per:
          <strong>{{ query }}</strong>
        </span>
        <v-spacer />
        <router-link :to="{ name: 'Search', query: { q: query } }">
          MOSTRA TUTTI I PRODOTTI
        </router-link>
      </div>
      <div class="simple-product-list-slider">
        <swiper :options="swiperOption" ref="swiperRef">
          <swiper-slide v-for="product in products" :key="product.productId">
            <ProductCard v-bind:product="product" />
          </swiper-slide>
        </swiper>
        <div
          class="swiper-pagination"
          :class="paginationClass"
          slot="pagination"
          v-if="products.length > 1"
        ></div>
      </div>
    </div>
    <div class="search-result search-result-no-product mb-6" v-else>
      <span class="text-h6">
        La ricerca non ha prodotto risultati per: <strong>{{ query }}</strong>
      </span>
    </div>
  </div>
</template>
<style lang="scss">
.simple-product-list-slider {
  .swiper-pagination {
    position: initial;
    margin-bottom: 20px;
  }
}
</style>
<script>
import ProductCard from "@/components/product/ProductCard";
import ProductService from "~/service/productService";
import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "SimpleProductListSlider",
  props: {
    query: { type: String, required: true },
    paginationClass: { type: String, required: false },
    showPagination: { type: Boolean, default: false },
    showArrows: { type: Boolean, default: true }
  },
  mixins: [deliveryReactive],
  components: { ProductCard },
  data() {
    return {
      products: null,
      page: null,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 12,
        watchOverflow: true,
        pagination: {
          el: "." + this.paginationClass,
          clickable: true
        },
        navigation: {
          prevEl: `#product-list-slider-prev-${this.paginationClass}`,
          nextEl: `#product-list-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          320: {
            slidesPerView: 1.5,
            centeredSlides: true
          },
          600: {
            slidesPerView: 1.5,
            slidesPerGroup: 1.5,
            centeredSlides: true
          },
          960: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            centeredSlides: false
          },
          1280: {
            slidesPerView: 4,
            slidesPerGroup: 3,
            centeredSlides: false
          },
          1590: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            centeredSlides: false
          }
        }
      }
    };
  },
  methods: {
    async doSearchWord(word) {
      let response = await ProductService.search({
        q: word,
        page_size: 16
      });
      this.products = response.products;
      this.page = response.page;
    },
    reload() {
      this.doSearchWord(this.query);
    }
  },
  computed: {
    itemsPerPage() {
      return Math.min(this.page.itemsPerPage, this.page.totItems);
    }
  },
  mounted() {
    if (this.query) {
      this.reload();
    }
  }
};
</script>
